@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,700,700i&display=swap");

body {
  font-family: "Montserrat", sans-serif;
}

a:hover {
  color: inherit;
}

.text-green {
  color: #bff082;
}

/* Loader */
$primary-color: #8c78ca;
@import "~loaders.css/src/animations/pacman.scss";

.loader-hidden {
  display: none;
}
.loader-active {
  display: block;
}

.img-error,
.img-confirm {
  max-height: 6rem;
  max-width: 6rem;
}

/* HEADER */

#session a {
  color: #8c78ca;
  font-size: 0.8rem;
  font-weight: 700;
}

h1 {
  background: linear-gradient(to right, #866dcc 0px, #ff9eea 240px);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #ff9eea;
  font-weight: 700;
  font-size: 2.4rem;
}

/* OVERVIEW */

.overview table {
  width: 100%;
  font-size: 0.9rem;
}
.overview thead {
  border-bottom: 1px solid #eee;
}

.overview th {
  color: #bbb;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: center;
}

.overview td {
  vertical-align: top;
  padding: 25px 5px;
}
.overview th {
  padding: 10px;
}

.overview .t-lugares input {
  width: 60px;
  height: 40px;
  border: none;
  background-color: #eee;
  padding: 10px;
}

.overview .t-precio,
.overview .t-total {
  font-weight: 700;
  font-size: 1.2em;
}

.overview figure,
.resumen figure {
  border-radius: 50%;
  width: 90px;
  height: 90px;
  overflow: hidden;
  margin-right: 15px;
  display: inline-block;
  text-align: center;
}

figure img {
  height: 100%;
}

.resumen figure {
  width: 50px;
  height: 50px;
}

/* PRECIO */

.price {
  font-weight: 700;
}

.price span {
  font-size: 30px;
  display: inline-block;
  margin-left: 30px;
}

.but {
  border: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.7rem;
  padding: 15px 35px;
  display: inline-block;
}

.but-green {
  background-color: #bff082;
}

.but-yellow {
  background-color: #ffeb8a;
}

.but-purple {
  background-color: #8c78ca;
  color: #fff;
}

.but-blue {
  background-color: #123283;
  color: #fff;
}

.but-cyan {
  background-color: #2f80ed;
  color: #fff;
}
.but-gray {
  background-color: #484848;
  color: #fff;
}

.but-grey {
  background-color: #9e9e9e;
  color: #fff;
}


.descuento input {
  background-color: rgba(0, 0, 0, 0.04);
  border: none;
}

/* FORMULARIO */

.formulario input,
.formulario select {
  border-radius: 0;
  border: none;
  padding: 5px;
  background-color: #eff1f3;
}

.formulario-input {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip:padding-box;
  height: calc(1.5em + .75rem + 2px);
  border-radius: 0;
  border: none;
  padding: 5px;
  background-color: #eff1f3;
}
.formulario-input-error {
  color: red;
  font-size: 0.7rem;
}

.btn-outline-info {
  background-color: white !important;
  border-color: #8c78ca !important;
  outline-color: #8c78ca !important;
  color: #8c78ca !important;
  width: 12rem;
  height: 2.5rem;
  margin-bottom: 2.5rem;
}
.btn-outline-info:not(:disabled):not(.disabled):focus{
  box-shadow: none !important;
}

.btn-outline-info.active,.btn-outline-info:active{
  background: #8c78ca!important;
  outline: none;
  box-shadow: none !important;
  color: white !important;
}

.formulario {
  h6{
  font-size: 1.125rem;
  line-height: 1.125rem;
  }
  p{
  font-weight: 400;
  font-size: .88rem;
  line-height: 1.18rem;
  }
}

.form-info,
.form-info h6 
{
  font-size: 1.125rem;
}

.form-control-option{
  font-size: 14px;
  display: grid;
  grid-template-columns: 24px auto;
  gap: 8px;
  border: 2px solid rgba(12, 12, 12, 0.1);
  border-radius: 8px;
  width: 100%;
  padding: 1rem 1.5rem;
  
  &--active{
    border: 2px solid #8F37FF;
  }

  input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 0;
    font: inherit;
    color: #e1e1e8;
    width: 24px;
    height: 24px;
    border: 2px solid #e1e1e8;
    border-radius: 50%;
    transform: translateY(-0.075rem);
    display: grid;
    place-content: center;
  
    &:before {
      content: "";
      width: 14px;
      height: 14px;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1rem 1rem #8f37ff;
      background-color: CanvasText;
     }
    &:checked{
      border: 2px solid #0c0c0c;
    }

    &:checked::before {
      transform: scale(1);
    }
  }
}

.form-control-option-disabled{
  cursor: not-allowed;
  opacity: 0.6;
}

/* RESUMEN */

.resumen-total {
  font-weight: 700;
}

.precio-total {
  background: linear-gradient(to right, #784afb 0px, #fd04c7 100px);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #ff9eea;
  font-weight: 700;
  font-size: 1.4rem;
  margin-left: 35px;
}

/* NEWSLETTER */
#newsletter input {
  
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
}

/* FOOTER */

#footer {
  margin-top: 5%;
  font-size: 0.7em;
  color: #fff;
  clear: both;
  position: relative;
  width: 100%;
}

footer a {
  color: #fff;
}

#footer .logo-footer {
  max-width: 150px;
}

#footer nav a {
  margin-bottom: 10px;
}

#newsletter {
  position: absolute;
  width: 100%;
  background-color: #2b253e;
}

#info-contact {
  position: absolute;
  width: 100%;
  top:5rem;
  background-color: #211d2f;
}

.copyright {
  font-size: 0.9em;
}

.copyright a {
  display: inline-block;
}

/* PASOS */

.steps {
  padding-left: 0;
}

.step-finish {
  background: url("./img/back-steps-01.png") no-repeat center right;
}

.step-middle-01 {
  background: url("./img/back-steps-02.png") no-repeat center right;
}

.step-middle-02 {
  background: url("./img/back-steps-04.png") no-repeat center right;
}

.step-end {
  background: url("./img/back-steps-03.png") no-repeat center right;
}

.step-end-02 {
  background: url("./img/back-steps-05.png") no-repeat center right;
}

.steps .step-text {
  display: inline-block;
  height: 30px;
  padding: 10px 5px 8px 10px;
  font-size: 0.5rem;
  line-height: 0.5rem;
  background-color: #f3f3f3;
  color: #bdbdbd;
  padding-right: 13px;
  width: 100%;
  text-align: center;
}

.steps .active .step-text {
  background-color: #ea186d;
  color: #fff;
}

.step-text a {
  color: #fff;
}

.step-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 15px 20px;
  border-color: transparent transparent transparent #ea186d;
  display: inline-block;
}

.add-cinta {
  border: 1px solid #784afb;
  cursor: pointer;
  h5,
  i {
    color: #784afb;
    font-size: 24px;
  }

  &:hover {
    background: #784afb;
    h5,
    i {
      color: #fff;
    }
  }
}

.discount-input {
  text-transform: uppercase;
}

.Confirm {

  h3 {
    font-weight: bold;
    font-size: 2.5rem !important;
  }

  .but{
    width: 90%;
    border-radius: 40px;
  }

}

/* FACTURA */


#necesito-factura {
  background-color: #f8f8f8;
  font-size: .9rem;
  input, select{
    font-size: .9rem;
  }
}
.sedeIcon{
  width: 50px;
  height: 50px;
  border-radius: 25px;
}


.modalLarge{

  .modal-content{
    width: 120%;
    @media only screen and (max-width: 576px){
      width: 100%;
    }
    .modal-body{
      padding: 0;
    }
  }
}

.sedeName{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  font-weight: bold;
  font-size: 18px;

  input{
    visibility: hidden;
    opacity: 0;
  }
}

.selected{
    background: #784afb;
    color: #fff;
    text-align: center;
    border-radius: 40px;
    font-weight: bold;
}

#confirmation .detalles .col {
  background-color: #f6f6f6;
}

#confirmation img {
  max-width: 100px;
}

.billing-data{
  font-size: 16px;
  color: #8c78ca;
  font-weight: bold;
}
.simple-link{
  text-decoration: underline;
  font-weight: bold;
  color: #8c78ca;
}

.modal-wait-text{
  text-align: center;
  font-style: italic;
  font-size: 14px;
}

.option-select-pay{
  height: 110px;
}

$layout-breakpoint-small: 960px;

@media (max-width: $layout-breakpoint-small) {
  .option-select-pay{
    height: 60px;
  }
}

