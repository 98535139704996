@import url(https://fonts.googleapis.com/css?family=Montserrat:400,400i,700,700i&display=swap);
body {
  font-family: "Montserrat", sans-serif; }

a:hover {
  color: inherit; }

.text-green {
  color: #bff082; }

/* Loader */
@-webkit-keyframes rotate_pacman_half_up {
  0% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }
  50% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); } }
@keyframes rotate_pacman_half_up {
  0% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }
  50% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); } }

@-webkit-keyframes rotate_pacman_half_down {
  0% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); } }

@keyframes rotate_pacman_half_down {
  0% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); } }

@-webkit-keyframes pacman-balls {
  75% {
    opacity: 0.7; }
  100% {
    -webkit-transform: translate(-100px, -6.25px);
            transform: translate(-100px, -6.25px); } }

@keyframes pacman-balls {
  75% {
    opacity: 0.7; }
  100% {
    -webkit-transform: translate(-100px, -6.25px);
            transform: translate(-100px, -6.25px); } }

.pacman {
  position: relative; }
  .pacman > div:nth-child(2) {
    -webkit-animation: pacman-balls 1s -0.99s infinite linear;
            animation: pacman-balls 1s -0.99s infinite linear; }
  .pacman > div:nth-child(3) {
    -webkit-animation: pacman-balls 1s -0.66s infinite linear;
            animation: pacman-balls 1s -0.66s infinite linear; }
  .pacman > div:nth-child(4) {
    -webkit-animation: pacman-balls 1s -0.33s infinite linear;
            animation: pacman-balls 1s -0.33s infinite linear; }
  .pacman > div:nth-child(5) {
    -webkit-animation: pacman-balls 1s 0s infinite linear;
            animation: pacman-balls 1s 0s infinite linear; }
  .pacman > div:first-of-type {
    width: 0px;
    height: 0px;
    border-right: 25px solid transparent;
    border-top: 25px solid #8c78ca;
    border-left: 25px solid #8c78ca;
    border-bottom: 25px solid #8c78ca;
    border-radius: 25px;
    -webkit-animation: rotate_pacman_half_up 0.5s 0s infinite;
            animation: rotate_pacman_half_up 0.5s 0s infinite;
    position: relative;
    left: -30px; }
  .pacman > div:nth-child(2) {
    width: 0px;
    height: 0px;
    border-right: 25px solid transparent;
    border-top: 25px solid #8c78ca;
    border-left: 25px solid #8c78ca;
    border-bottom: 25px solid #8c78ca;
    border-radius: 25px;
    -webkit-animation: rotate_pacman_half_down 0.5s 0s infinite;
            animation: rotate_pacman_half_down 0.5s 0s infinite;
    margin-top: -50px;
    position: relative;
    left: -30px; }
  .pacman > div:nth-child(3),
  .pacman > div:nth-child(4),
  .pacman > div:nth-child(5),
  .pacman > div:nth-child(6) {
    background-color: #8c78ca;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin: 2px;
    width: 10px;
    height: 10px;
    position: absolute;
    -webkit-transform: translate(0, -6.25px);
            transform: translate(0, -6.25px);
    top: 25px;
    left: 70px; }

.loader-hidden {
  display: none; }

.loader-active {
  display: block; }

.img-error,
.img-confirm {
  max-height: 6rem;
  max-width: 6rem; }

/* HEADER */
#session a {
  color: #8c78ca;
  font-size: 0.8rem;
  font-weight: 700; }

h1 {
  background: -webkit-linear-gradient(left, #866dcc 0px, #ff9eea 240px);
  background: linear-gradient(to right, #866dcc 0px, #ff9eea 240px);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #ff9eea;
  font-weight: 700;
  font-size: 2.4rem; }

/* OVERVIEW */
.overview table {
  width: 100%;
  font-size: 0.9rem; }

.overview thead {
  border-bottom: 1px solid #eee; }

.overview th {
  color: #bbb;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: center; }

.overview td {
  vertical-align: top;
  padding: 25px 5px; }

.overview th {
  padding: 10px; }

.overview .t-lugares input {
  width: 60px;
  height: 40px;
  border: none;
  background-color: #eee;
  padding: 10px; }

.overview .t-precio,
.overview .t-total {
  font-weight: 700;
  font-size: 1.2em; }

.overview figure,
.resumen figure {
  border-radius: 50%;
  width: 90px;
  height: 90px;
  overflow: hidden;
  margin-right: 15px;
  display: inline-block;
  text-align: center; }

figure img {
  height: 100%; }

.resumen figure {
  width: 50px;
  height: 50px; }

/* PRECIO */
.price {
  font-weight: 700; }

.price span {
  font-size: 30px;
  display: inline-block;
  margin-left: 30px; }

.but {
  border: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.7rem;
  padding: 15px 35px;
  display: inline-block; }

.but-green {
  background-color: #bff082; }

.but-yellow {
  background-color: #ffeb8a; }

.but-purple {
  background-color: #8c78ca;
  color: #fff; }

.but-blue {
  background-color: #123283;
  color: #fff; }

.but-cyan {
  background-color: #2f80ed;
  color: #fff; }

.but-gray {
  background-color: #484848;
  color: #fff; }

.but-grey {
  background-color: #9e9e9e;
  color: #fff; }

.descuento input {
  background-color: rgba(0, 0, 0, 0.04);
  border: none; }

/* FORMULARIO */
.formulario input,
.formulario select {
  border-radius: 0;
  border: none;
  padding: 5px;
  background-color: #eff1f3; }

.formulario-input {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  height: calc(1.5em + .75rem + 2px);
  border-radius: 0;
  border: none;
  padding: 5px;
  background-color: #eff1f3; }

.formulario-input-error {
  color: red;
  font-size: 0.7rem; }

.btn-outline-info {
  background-color: white !important;
  border-color: #8c78ca !important;
  outline-color: #8c78ca !important;
  color: #8c78ca !important;
  width: 12rem;
  height: 2.5rem;
  margin-bottom: 2.5rem; }

.btn-outline-info:not(:disabled):not(.disabled):focus {
  box-shadow: none !important; }

.btn-outline-info.active, .btn-outline-info:active {
  background: #8c78ca !important;
  outline: none;
  box-shadow: none !important;
  color: white !important; }

.formulario h6 {
  font-size: 1.125rem;
  line-height: 1.125rem; }

.formulario p {
  font-weight: 400;
  font-size: .88rem;
  line-height: 1.18rem; }

.form-info,
.form-info h6 {
  font-size: 1.125rem; }

.form-control-option {
  font-size: 14px;
  display: grid;
  grid-template-columns: 24px auto;
  grid-gap: 8px;
  gap: 8px;
  border: 2px solid rgba(12, 12, 12, 0.1);
  border-radius: 8px;
  width: 100%;
  padding: 1rem 1.5rem; }
  .form-control-option--active {
    border: 2px solid #8F37FF; }
  .form-control-option input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
    background-color: transparent;
    margin: 0;
    font: inherit;
    color: #e1e1e8;
    width: 24px;
    height: 24px;
    border: 2px solid #e1e1e8;
    border-radius: 50%;
    -webkit-transform: translateY(-0.075rem);
            transform: translateY(-0.075rem);
    display: grid;
    place-content: center; }
    .form-control-option input[type="radio"]:before {
      content: "";
      width: 14px;
      height: 14px;
      border-radius: 50%;
      -webkit-transform: scale(0);
              transform: scale(0);
      -webkit-transition: 120ms -webkit-transform ease-in-out;
      transition: 120ms -webkit-transform ease-in-out;
      transition: 120ms transform ease-in-out;
      transition: 120ms transform ease-in-out, 120ms -webkit-transform ease-in-out;
      box-shadow: inset 1rem 1rem #8f37ff;
      background-color: CanvasText; }
    .form-control-option input[type="radio"]:checked {
      border: 2px solid #0c0c0c; }
    .form-control-option input[type="radio"]:checked::before {
      -webkit-transform: scale(1);
              transform: scale(1); }

.form-control-option-disabled {
  cursor: not-allowed;
  opacity: 0.6; }

/* RESUMEN */
.resumen-total {
  font-weight: 700; }

.precio-total {
  background: -webkit-linear-gradient(left, #784afb 0px, #fd04c7 100px);
  background: linear-gradient(to right, #784afb 0px, #fd04c7 100px);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #ff9eea;
  font-weight: 700;
  font-size: 1.4rem;
  margin-left: 35px; }

/* NEWSLETTER */
#newsletter input {
  background-color: rgba(0, 0, 0, 0.2);
  border: none; }

/* FOOTER */
#footer {
  margin-top: 5%;
  font-size: 0.7em;
  color: #fff;
  clear: both;
  position: relative;
  width: 100%; }

footer a {
  color: #fff; }

#footer .logo-footer {
  max-width: 150px; }

#footer nav a {
  margin-bottom: 10px; }

#newsletter {
  position: absolute;
  width: 100%;
  background-color: #2b253e; }

#info-contact {
  position: absolute;
  width: 100%;
  top: 5rem;
  background-color: #211d2f; }

.copyright {
  font-size: 0.9em; }

.copyright a {
  display: inline-block; }

/* PASOS */
.steps {
  padding-left: 0; }

.step-finish {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAeCAIAAACT/LgdAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAWJJREFUeNqMlDFIw1AURZM0RYOmER2lo5PgJoKTQ5euXRzqEjCrOrgq4iY4WNcIWXRwsGs7dhJci06OpWNt0yix2FIPfClS6n9efkICl5/7bk5iJicPTrDTLVyM+6mhlZWGja/HVy8KDEkWx/tp1fScxfOSbOXRfT+c391iCVY0ar3F/o17VbbXVwUrInFydLdUPTBzjmBFn/dPg1oTt2xFbMyZJLIV9UrXc8UNypatFIJ74biY3V4TrGj40qZsL9rP5JcFqxqRlYuCSSGWpnM2HsepWynLVhT7YSa/Qm6ubb2VEZPDW5oePrct498SrMzkVvYAdVBvClY4HrU6H5c1ISsEw3Hih+r2T6vC9/eHNDsA1LIlBMOxbixGoR3yQbDQAD6oZWqhLEWqonY61dQokNrZPJs9wOQKOtlS8+/4CQCX0MlzIVX3YhkZLhWgAgNUCJfQqX/JNp8bKYkokvUtwAA/KJmoF0w+BwAAAABJRU5ErkJggg==) no-repeat center right; }

.step-middle-01 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAeCAIAAACT/LgdAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAARhJREFUeNpifCWRyygtyL4pl5GPgwEvYALi/0/f/8pawkAIMEGofyfv/W7dSpRSIPiz4OjfdeeIUgoEv8rX/Lv+nCilINUxs/9/+kGUUqA6oGqilIK8eP050CVEKQUCoP+AviRKKRAAw+7fyftEKQV5MWsxMHaIUgryYuYSeIAw4Y8hoBd/w7zIRDDq/+659mfyXiCDhYEI8HvSXkZNSSYGogFRSlnznJldtAgrBSpiyXUmbCqTpiRrZwhhBwCzENv0GHhGwqeUbVosMNsR9hZrtTeTuSLhEGAOMmJJsCYcWECvsMG8gk8pyCtLUomKAqA6XGUHilKgvUDbCUcs0B9A3xBOA0zmSsDQIZxcgOHMNi2GYGIACDAArpJXy22cfD0AAAAASUVORK5CYII=) no-repeat center right; }

.step-middle-02 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAeCAIAAACT/LgdAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAPxJREFUeNqMlFkOgzAMBYlZAve/K/vSkSxFCEJeLbVfA36L2zDPc9d14zhe11UVx7ZtO46j7/tKjfFZliWEEGPUKKuR0TRN27YCZc7zhObFZiZQBsUoGYYBMQJl3CK0Rhlk8J0NJKNsmqa6rt8WMyiBQNMLDwjUA1nXFRn3QD6jweK+79ApECtkTnaISS1auUwCQQO6Neqdkwa1W/X3CBRPOHOLAoXz4MRb8c5byUEIcCt4Sj+kPOoBwbG9ZIul3CH6OEiRABwQrkVYfql+tSUUKxweFygqACIduK//DkuWWU2Ed8sZ1NujureVJ8pelqZWPlG3krX8mJ8AAwDd5JkVZbC3kwAAAABJRU5ErkJggg==) no-repeat center right; }

.step-end {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAeCAIAAACT/LgdAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAMtJREFUeNqMlNsKhDAMRNu0a/X/P9UH7c3uYGEJ4iYZEH04JJlc9Pu+E9G2bd57J4rwXNd1nqfTRPPVe885m1Co1tpaM6EQykAxJhQ6jmOMYULBgTahsyGvFuk1QL1lQiEERgdN6GwItyih0+KPJnlC3CKpo8cISyn4iM4goNg+cmaZ0GVZYow6CgioHhUVppT0AnBC/JAkdF1XfnB/UeQNIegd+NzSm8WtSOi0YhqB8O+gh2Vh1MStYDD6DqAvr1aeKJIitboMXwEGAE0WV03ePLijAAAAAElFTkSuQmCC) no-repeat center right; }

.step-end-02 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAeCAIAAACT/LgdAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAARdJREFUeNpieCWR+9a0/t/Hb/8JASYGBoa/j999TJzDQAgwQajfx25/qVtHlFIg+D77wI+VJ4lSCgSfC5b+ufqUKKVA8CFo0v9P34lSClQHVE2UUiAAugHoEqKUAgHQf0BfEqUUCIBh9/vYHaKUAsHHxNnA2CFKKdCLnxJnwwOECX8MgbyYv5QopUDwc8elb73bgQwWBiLA157tzNrSTAxEA6KUcpd4snvoEVYKVMRV7EnYVBZtad6J0YQdwMjHyTc/FUgSVso/P5VZVoiwt3iaglitVAiHAEe4OWeqA+HAAnllQjThcAV6QmBdHlFRAFQH9zI+pUB7gbYTjligP4C+IZwGWK1UgaFDOLkAw5l/fgrBxAAQYAC1rnvXbog8xgAAAABJRU5ErkJggg==) no-repeat center right; }

.steps .step-text {
  display: inline-block;
  height: 30px;
  padding: 10px 5px 8px 10px;
  font-size: 0.5rem;
  line-height: 0.5rem;
  background-color: #f3f3f3;
  color: #bdbdbd;
  padding-right: 13px;
  width: 100%;
  text-align: center; }

.steps .active .step-text {
  background-color: #ea186d;
  color: #fff; }

.step-text a {
  color: #fff; }

.step-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 15px 20px;
  border-color: transparent transparent transparent #ea186d;
  display: inline-block; }

.add-cinta {
  border: 1px solid #784afb;
  cursor: pointer; }
  .add-cinta h5,
  .add-cinta i {
    color: #784afb;
    font-size: 24px; }
  .add-cinta:hover {
    background: #784afb; }
    .add-cinta:hover h5,
    .add-cinta:hover i {
      color: #fff; }

.discount-input {
  text-transform: uppercase; }

.Confirm h3 {
  font-weight: bold;
  font-size: 2.5rem !important; }

.Confirm .but {
  width: 90%;
  border-radius: 40px; }

/* FACTURA */
#necesito-factura {
  background-color: #f8f8f8;
  font-size: .9rem; }
  #necesito-factura input, #necesito-factura select {
    font-size: .9rem; }

.sedeIcon {
  width: 50px;
  height: 50px;
  border-radius: 25px; }

.modalLarge .modal-content {
  width: 120%; }
  @media only screen and (max-width: 576px) {
    .modalLarge .modal-content {
      width: 100%; } }
  .modalLarge .modal-content .modal-body {
    padding: 0; }

.sedeName {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  font-weight: bold;
  font-size: 18px; }
  .sedeName input {
    visibility: hidden;
    opacity: 0; }

.selected {
  background: #784afb;
  color: #fff;
  text-align: center;
  border-radius: 40px;
  font-weight: bold; }

#confirmation .detalles .col {
  background-color: #f6f6f6; }

#confirmation img {
  max-width: 100px; }

.billing-data {
  font-size: 16px;
  color: #8c78ca;
  font-weight: bold; }

.simple-link {
  text-decoration: underline;
  font-weight: bold;
  color: #8c78ca; }

.modal-wait-text {
  text-align: center;
  font-style: italic;
  font-size: 14px; }

.option-select-pay {
  height: 110px; }

@media (max-width: 960px) {
  .option-select-pay {
    height: 60px; } }

